body {
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  background-color: #F6F7FB;
  font-family: 'Inter', sans-serif;
  line-height: 1.5;
  width: 100%;
  overflow-x: hidden;
  transition: background-color 300ms ease-in-out;
}

body.is-dark {
  color: #FFFFFF;
  background-color: #303030;
}

body.is-high-contrast {
  color: #000000;
  background-color: #FFFFFF;
}

body.is-dark.is-high-contrast {
  color: #FFFFFF;
  background-color: #000000;
}

body.is-high-contrast .Mui-disabled {
  cursor: not-allowed;
  pointer-events: initial;
}

input::placeholder {
  color: #000000;
  opacity: 0.7;
}

body.is-dark input::placeholder {
  color: #FFFFFF;
  opacity: 0.7;
}

body.is-high-contrast input::placeholder {
  opacity: 1;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-family: 'CircularStd';
}

h1, h2, h3 {
  font-weight: 400;
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-weight: 500;
}

p {
  margin-top: 10px;
  margin-bottom: 10px;
}

a {
  text-decoration: none;
}

.html-field a,
.text-editor a {
  color: #2196F3;
  text-decoration: underline;
}

.html-field a:hover {
  text-decoration: none
}

body.is-dark .html-field a,
body.is-dark .text-editor a {
  color: #90CAF9;
}

body.is-high-contrast .html-field a,
body.is-high-contrast .text-editor a {
  color: #000000;
}

body.is-dark.is-high-contrast .html-field a,
body.is-dark.is-high-contrast .text-editor a {
  color: #FFFFFF;
}
